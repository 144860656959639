<template>
  <v-row id="initial" :style="$vuetify.breakpoint.xsOnly ? '' : 'width: 100%'">
    <form
      action
      style="width: 100%"
      data-vv-scope="tattooer-selection-form"
      @submit.prevent="validateForm('tattooer-selection-form')"
    >
      <v-row class="ml-sm-2">
        <v-col
          cols="12"
          sm="6"
          class="py-0 mb-2 mb-sm-0"
          v-if="!$route.params.pre"
        >
          <div class="text-center text-sm-left">
            <v-btn
              v-for="service in services"
              :key="service.id"
              color="primary"
              @click="tab = service.name"
              elevation="0"
              :outlined="tab !== service.name"
              style="height: 30px; width: 110px !important"
              class="mr-3 mt-2"
            >
              {{ $tc(service.name, 2) }}
            </v-btn>
          </div>
        </v-col>
        <v-spacer v-else />
        <v-col cols="12" sm="6" class="py-0 mt-2 mt-sm-0" align="right">
          <div class="text-center text-sm-right">
            <v-btn
              v-if="$route.params.pre"
              color="primary"
              @click="
                $router.push({
                  name: 'customer_inscription_studio_pre',
                  params: {
                    token: $route.params.token,
                    back: true,
                  },
                })
              "
              outlined
              style="height: 30px; width: 115px !important"
            >
              <v-icon>mdi-arrow-left</v-icon>
              {{ $t("back") }}
            </v-btn>

            <v-btn
              :disabled="studio.tattooers.length === 0"
              color="primary"
              type="submit"
              style="height: 30px"
              elevation="0"
              class="ml-2"
            >
              {{ $t("next") }}
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="studio.tattooers.length" class="ml-sm-2">
        <v-col>
          <v-radio-group
            :style="$vuetify.breakpoint.xsOnly ? '' : 'width: 100%'"
            v-model="customer.tattooer"
            data-vv-validate-on="blur"
            v-validate="'required'"
            :error-messages="errors.collect('tattooer-selection-form.tattooer')"
            data-vv-name="tattooer"
          >
            <v-row
              style="
                min-width: 100% !important;
                display: flex;
                justify-center: center;
                cursor: default !important;
              "
              class="mx-0"
            >
              <v-col
                v-if="!$route.params.pre && false"
                cols="6"
                sm="6"
                md="3"
                :lg="size"
                class="py-0"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'display: flex; justify-content:center;cursor:default !important'
                    : 'center;cursor:default !important'
                "
              >
                <v-card
                  @click="selectTattooer(help)"
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'width: 93%; height: 20em'
                      : 'width: 100%; height: 20em'
                  "
                  class="my-2"
                  v-bind:class="[
                    {
                      select: customer.tattooer
                        ? help.id === customer.tattooer.id
                        : '',
                    },
                  ]"
                >
                  <v-row
                    style="text-align: center; max-width: 100% !important"
                    class="mr-0 ml-0"
                  >
                    <v-radio
                      style="position: absolute; left: 5px; top: 5px"
                      :value="help"
                    ></v-radio>
                    <v-avatar
                      color="primary"
                      size="100"
                      style="
                        margin: 0 auto;
                        font-size: 50px;
                        text-transform: uppercase;
                        color: #272727;
                        cursor: pointer;
                        margin-top: 50px;
                      "
                      >{{ nameAvatar("Asesoramiento") }}</v-avatar
                    >
                    <v-list-item-title
                      class="pt-7"
                      style="
                        color: var(--v-primary-base);
                        text-transform: uppercase;
                        font-size: 16px;
                        cursor: pointer;
                      "
                    >
                      {{ $t("assistance") }} </v-list-item-title
                    ><br />
                    <v-list-item-subtitle style="color: #999" class="pt-3">
                      Estudio: {{ studio.studio_name }}
                      <p>
                        {{ studio.city }}
                      </p>
                    </v-list-item-subtitle>
                  </v-row>
                </v-card>
              </v-col>

              <v-col
                cols="6"
                sm="6"
                md="3"
                :lg="size"
                v-for="(tattooer, i) in artist"
                :key="i"
                class="py-0 px-1"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'display: flex; justify-content:center;cursor:default !important'
                    : 'center;cursor:default !important'
                "
              >
                <v-card
                  @click="
                    selectTattooer(tattooer);
                    tattooer.selected = true;
                  "
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'width: 93%; height: 20em'
                      : 'width: 100%; height: 20em'
                  "
                  class="my-2"
                  v-bind:class="[
                    {
                      select: customer.tattooer
                        ? tattooer.id === customer.tattooer.id
                        : '',
                    },
                  ]"
                >
                  <v-row
                    style="text-align: center; max-width: 100% !important"
                    class="mr-0 ml-0"
                  >
                    <v-radio
                      style="position: absolute; left: 5px; top: 5px"
                      :value="tattooer"
                    ></v-radio>
                    <v-avatar
                      color="primary"
                      size="100"
                      style="
                        margin: 0 auto;
                        font-size: 50px;
                        text-transform: uppercase;
                        color: #272727;
                        cursor: pointer;
                        margin-top: 50px;
                      "
                      >{{
                        nameAvatar(
                          tattooer.user.first_name +
                            " " +
                            tattooer.user.last_name
                        )
                      }}</v-avatar
                    >
                    <v-list-item-title
                      class="pt-7 px-1"
                      style="
                        color: var(--v-primary-base);
                        text-transform: uppercase;
                        font-size: 16px;
                        cursor: pointer;
                      "
                      >{{ tattooer.user.first_name }}
                      {{ tattooer.user.last_name }}</v-list-item-title
                    ><br />
                    <v-list-item-subtitle style="color: #999" class="pt-3 px-1">
                      Estudio: {{ studio.studio_name }}
                      <p>
                        {{ studio.city }}
                      </p>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="color: #999" class="pt-3 px-1">
                      Estancia: Del
                      {{
                        $d(
                          new Date(tattooer.tattooer_studios[0].from),
                          "date2digits"
                        )
                      }}
                      <span v-if="tattooer.tattooer_studios[0].to"
                        >al
                        {{
                          $d(
                            new Date(tattooer.tattooer_studios[0].to),
                            "date2digits"
                          )
                        }}</span
                      >
                    </v-list-item-subtitle>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <h3
                class="primary--text"
                style="text-align: center"
                v-html="
                  $t('inscriptions.no_tattooer', { email: studio.user.email })
                "
              ></h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </form>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TattooerSelect",
  data() {
    return {
      tab: "tattooer",

      duration: 500,
      offset: 0,
      easing: "easeInOutCubic",
      help: { id: -1, tattoo_type: [] },
    };
  },
  mounted() {
    this.fetchType();
    if (this.$route.params.artist) {
      this.tab = this.$route.params.artist;
    }
  },
  methods: {
    ...mapActions("tattooers", ["getTypeArtist"]),
    selectTattooer(tattooer) {
      this.$vuetify.goTo(0, this.options);

      this.$set(this.customer, "tattooer", tattooer);
      this.$store.commit("inscriptions/SET_CUSTOMER", this.customer);
      this.$emit("validated", this.tab);
    },
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.$emit("validated", this.tab);
        }
      });
    },

    fetchType() {
      this.loading = true;
      this.getTypeArtist({
        pagination: this.options,
        filters: this.filters,
      }).then((tattooers) => {
        //console.log(tattooers);

        this.loading = false;
      });
    },
    nameAvatar(name) {
      //console.log("AVATAR");
      //console.log ("AVATAR", name);
      let array = name.split("/");

      let arr = array[array.length - 1];
      //console.log(arr);
      arr = arr.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });
      // //console.log(nom);
      return nom;
    },
  },
  computed: {
    services() {
      let s = this.customer.studio.user.setting.find(
        (x) => x.key === "studio_services"
      );
      if (s) {
        return JSON.parse(s.value);
      }
      return [];
    },
    assistance() {
      let s = this.customer.studio.user.setting.find(
        (x) => x.key === "assistance_appointment"
      );
      if (s) {
        return s.value === "1";
      }

      return true;
    },
    ...mapState("inscriptions", ["studioState", "customerState"]),
    studio: {
      get() {
        //console.log(this.studioState);
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
    customer: {
      get() {
        //console.log (this.customerState);
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
    size() {
      if (window.innerWidth <= 1478) {
        //console.log ("3");
        return "3";
      } else {
        //console.log ("2");
        return "2";
      }
    },
    artist() {
      let art = this.studio.tattooers.filter((x) => {
        /*console.log(
          x.type_artist.find((element) => {
            element.name == this.tab;
          })
        );*/
        return (
          x.type_artist.find((element) => {
            return element.name == this.tab;
          }) !== undefined
        );
      });
      //console.log(art);
      return art;
    },
  },
};
</script>

<style lang="sass">
.select
  border: 1px solid var(--v-primary-base) !important
.tab
  background-color: var(--v-primary-base) !important
</style>