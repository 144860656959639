var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:(_vm.$vuetify.breakpoint.xsOnly ? '' : 'width: 100%'),attrs:{"id":"initial"}},[_c('form',{staticStyle:{"width":"100%"},attrs:{"action":"","data-vv-scope":"tattooer-selection-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('tattooer-selection-form')}}},[_c('v-row',{staticClass:"ml-sm-2"},[(!_vm.$route.params.pre)?_c('v-col',{staticClass:"py-0 mb-2 mb-sm-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-center text-sm-left"},_vm._l((_vm.services),function(service){return _c('v-btn',{key:service.id,staticClass:"mr-3 mt-2",staticStyle:{"height":"30px","width":"110px !important"},attrs:{"color":"primary","elevation":"0","outlined":_vm.tab !== service.name},on:{"click":function($event){_vm.tab = service.name}}},[_vm._v(" "+_vm._s(_vm.$tc(service.name, 2))+" ")])}),1)]):_c('v-spacer'),_c('v-col',{staticClass:"py-0 mt-2 mt-sm-0",attrs:{"cols":"12","sm":"6","align":"right"}},[_c('div',{staticClass:"text-center text-sm-right"},[(_vm.$route.params.pre)?_c('v-btn',{staticStyle:{"height":"30px","width":"115px !important"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$router.push({
                name: 'customer_inscription_studio_pre',
                params: {
                  token: _vm.$route.params.token,
                  back: true,
                },
              })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",staticStyle:{"height":"30px"},attrs:{"disabled":_vm.studio.tattooers.length === 0,"color":"primary","type":"submit","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)])],1),(_vm.studio.tattooers.length)?_c('v-row',{staticClass:"ml-sm-2"},[_c('v-col',[_c('v-radio-group',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],style:(_vm.$vuetify.breakpoint.xsOnly ? '' : 'width: 100%'),attrs:{"data-vv-validate-on":"blur","error-messages":_vm.errors.collect('tattooer-selection-form.tattooer'),"data-vv-name":"tattooer"},model:{value:(_vm.customer.tattooer),callback:function ($$v) {_vm.$set(_vm.customer, "tattooer", $$v)},expression:"customer.tattooer"}},[_c('v-row',{staticClass:"mx-0",staticStyle:{"min-width":"100% !important","display":"flex","justify-center":"center","cursor":"default !important"}},[(!_vm.$route.params.pre && false)?_c('v-col',{staticClass:"py-0",style:(_vm.$vuetify.breakpoint.xsOnly
                  ? 'display: flex; justify-content:center;cursor:default !important'
                  : 'center;cursor:default !important'),attrs:{"cols":"6","sm":"6","md":"3","lg":_vm.size}},[_c('v-card',{staticClass:"my-2",class:[
                  {
                    select: _vm.customer.tattooer
                      ? _vm.help.id === _vm.customer.tattooer.id
                      : '',
                  } ],style:(_vm.$vuetify.breakpoint.xsOnly
                    ? 'width: 93%; height: 20em'
                    : 'width: 100%; height: 20em'),on:{"click":function($event){return _vm.selectTattooer(_vm.help)}}},[_c('v-row',{staticClass:"mr-0 ml-0",staticStyle:{"text-align":"center","max-width":"100% !important"}},[_c('v-radio',{staticStyle:{"position":"absolute","left":"5px","top":"5px"},attrs:{"value":_vm.help}}),_c('v-avatar',{staticStyle:{"margin":"0 auto","font-size":"50px","text-transform":"uppercase","color":"#272727","cursor":"pointer","margin-top":"50px"},attrs:{"color":"primary","size":"100"}},[_vm._v(_vm._s(_vm.nameAvatar("Asesoramiento")))]),_c('v-list-item-title',{staticClass:"pt-7",staticStyle:{"color":"var(--v-primary-base)","text-transform":"uppercase","font-size":"16px","cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.$t("assistance"))+" ")]),_c('br'),_c('v-list-item-subtitle',{staticClass:"pt-3",staticStyle:{"color":"#999"}},[_vm._v(" Estudio: "+_vm._s(_vm.studio.studio_name)+" "),_c('p',[_vm._v(" "+_vm._s(_vm.studio.city)+" ")])])],1)],1)],1):_vm._e(),_vm._l((_vm.artist),function(tattooer,i){return _c('v-col',{key:i,staticClass:"py-0 px-1",style:(_vm.$vuetify.breakpoint.xsOnly
                  ? 'display: flex; justify-content:center;cursor:default !important'
                  : 'center;cursor:default !important'),attrs:{"cols":"6","sm":"6","md":"3","lg":_vm.size}},[_c('v-card',{staticClass:"my-2",class:[
                  {
                    select: _vm.customer.tattooer
                      ? tattooer.id === _vm.customer.tattooer.id
                      : '',
                  } ],style:(_vm.$vuetify.breakpoint.xsOnly
                    ? 'width: 93%; height: 20em'
                    : 'width: 100%; height: 20em'),on:{"click":function($event){_vm.selectTattooer(tattooer);
                  tattooer.selected = true;}}},[_c('v-row',{staticClass:"mr-0 ml-0",staticStyle:{"text-align":"center","max-width":"100% !important"}},[_c('v-radio',{staticStyle:{"position":"absolute","left":"5px","top":"5px"},attrs:{"value":tattooer}}),_c('v-avatar',{staticStyle:{"margin":"0 auto","font-size":"50px","text-transform":"uppercase","color":"#272727","cursor":"pointer","margin-top":"50px"},attrs:{"color":"primary","size":"100"}},[_vm._v(_vm._s(_vm.nameAvatar( tattooer.user.first_name + " " + tattooer.user.last_name )))]),_c('v-list-item-title',{staticClass:"pt-7 px-1",staticStyle:{"color":"var(--v-primary-base)","text-transform":"uppercase","font-size":"16px","cursor":"pointer"}},[_vm._v(_vm._s(tattooer.user.first_name)+" "+_vm._s(tattooer.user.last_name))]),_c('br'),_c('v-list-item-subtitle',{staticClass:"pt-3 px-1",staticStyle:{"color":"#999"}},[_vm._v(" Estudio: "+_vm._s(_vm.studio.studio_name)+" "),_c('p',[_vm._v(" "+_vm._s(_vm.studio.city)+" ")])]),_c('v-list-item-subtitle',{staticClass:"pt-3 px-1",staticStyle:{"color":"#999"}},[_vm._v(" Estancia: Del "+_vm._s(_vm.$d( new Date(tattooer.tattooer_studios[0].from), "date2digits" ))+" "),(tattooer.tattooer_studios[0].to)?_c('span',[_vm._v("al "+_vm._s(_vm.$d( new Date(tattooer.tattooer_studios[0].to), "date2digits" )))]):_vm._e()])],1)],1)],1)})],2)],1)],1)],1):_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"primary--text",staticStyle:{"text-align":"center"},domProps:{"innerHTML":_vm._s(
                _vm.$t('inscriptions.no_tattooer', { email: _vm.studio.user.email })
              )}})])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }